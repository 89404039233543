import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import {
  FaBook,
  FaUser,
  FaCog,
  FaList,
  FaLanguage,
  FaChartBar,
  FaUsers,
  FaSignOutAlt,
  FaRegFileAlt,
  FaThemeco,
} from "react-icons/fa";
import {
  MdCategory,
  MdHomeFilled,
  MdOutlineDashboard,
  MdOutlineLibraryBooks,
  MdFestival
} from "react-icons/md";
import { IoAccessibility } from "react-icons/io5";
import { SiMicrosoftaccess } from "react-icons/si";
import { Dashboard } from "@11solutionspvtltd/eleven-solutions-common-website";
import { useDispatch } from "react-redux";
import { clearProfileData } from "@11solutionspvtltd/eleven-solutions-common-website";
import barelviLogo from "../../images/BarelviLogo.png";
import { IconFileTypeTxt } from "@tabler/icons-react";
const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearProfileData());
  };
  const menuGroups = [
    {
      heading: "ANALYTICS",
      items: [
        {
          label: "Dashboard",
          icon: <MdOutlineDashboard />,
          onClick: () => navigate("#"),
        },
        {
          label: "Performance",
          icon: <FaChartBar />,
          onClick: () => navigate("#"),
        },
      ],
    },
    {
      heading: "LIBRARY",
      items: [
        {
          label: "Books",
          icon: <MdOutlineLibraryBooks />,
          onClick: () => navigate("/admin/books"),
        },
        {
          label: "Pages",
          icon: <FaBook />,
          onClick: () => navigate("/admin/pages"),
        },
        {
          label: "Sections",
          icon: <FaList />,
          onClick: () => navigate("/admin/sections"),
        },
        {
          label: "Authors",
          icon: <FaUser />,
          onClick: () => navigate("/admin/author"),
        },
        {
          label: "Categories",
          icon: <MdCategory />,
          onClick: () => navigate("/admin/category"),
        },
        {
          label: "Languages",
          icon: <FaLanguage />,
          onClick: () => navigate("/admin/languages"),
        },
        {
          label: "BookRoles",
          icon: <IoAccessibility />,
          onClick: () => navigate("/admin/bookroles"),
        },
        {
          label: "BookAccess",
          icon: <SiMicrosoftaccess />,
          onClick: () => navigate("/admin/bookaccess"),
        },
        {
          label: "Aya",
          icon: <IconFileTypeTxt />,
          onClick: () => navigate("/admin/aya"),
        },
        {
          label: "Festivals",
          icon: <MdFestival />,
          onClick: () => navigate("/admin/Festivals"),
        },
      ],
    },
    {
      heading: "CUSTOMIZATION",
      items: [
        {
          label: "Users",
          icon: <FaUsers />,
          onClick: () => navigate("/admin/users"),
        },
        {
          label: "Taxonomies",
          icon: <FaCog />,
          onClick: () => navigate("/admin/taxinomies"),
        },
        {
          label: "Templates",
          icon: <FaRegFileAlt />,
          onClick: () => navigate("/admin/template"),
        },
        {
          label: "BackToHome",
          icon: <MdHomeFilled />,
          onClick: () => navigate("/home"),
        },
        {
          label: "Logout",
          icon: <FaSignOutAlt />,
          onClick: () => handleLogout(),
        },
      ],
    },
  ];

  return (
    <div className=" h-screen  bg-white shadow-md  flex flex-row w-full  border-r">
      <div className="md:w-80">
        <Dashboard menuGroups={menuGroups} logo={barelviLogo} />
      </div>
      <div className="w-full pr-4 overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default Sidebar;
