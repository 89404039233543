import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AddpageApi, EditPageApi, getPageByIdApi, getDataFromTaxonomy } from "../../../api/Pages";
import { getBooksData } from "../../../api/Books";
import { usePageContext } from "./PageContext";
import { Editor } from "@11solutionspvtltd/eleven-solutions-common-website";
import { toast } from "react-toastify";

// import {toast} from "react-hot-toast"
import data from "../../../utils/DummyData";
import { SiGhost } from "react-icons/si";

interface Taxonomy{
  id : string,
  code: string,
  value: string,
  type: string,
}

function FormPages() {
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState<string>("");
  const [sequence, setSequence] = useState<number>(0);
  const [content, setContent] = useState("");
  const [value, setValue] = useState("");
  const [contentWOutHTML, setValueWOutHTML] = useState("");
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [bookId, setBookId] = useState<string>("");
  const [books, setBooks] = useState<{ id: string; name: string }[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const location = useLocation();
  const { id } = location.state || {};
 
  const { selectedBookName } = usePageContext();
  const [pagePublishStatus, setPagePublishStatus] = useState<number>(Number);
  const [displayMode, setDisplayMode] = useState<string>("");


  const [taxonomyData, setTaxonomyData] = useState<Taxonomy[]>([])
  const [type, setType] = useState<string>("");
  const [format, setFormat] = useState<string>("");
  const [fontType, setFontType] = useState<string>("");
  const [renderType, setRenderType] = useState<string>("");
  const [plainText, setPlainText] = useState<string>("");

  

  // console.log('jsheaf',taxonomyData)
  
// console.log('jsheaf',typeof(type), type)
// console.log('jsheaf',typeof(format), {format})
// console.log('jsheaf',typeof(fontType), {fontType})
// console.log('jsheaf',typeof(renderType), {renderType})

console.log("value", value);
console.log("content", content)
console.log("plain", plainText)

  console.log({
    pageNo,
    sequence,
    content: value, 
    bookId,
    type,
    format,
    fontType,
    renderType,
  });

 

  useEffect(() => {
    const fetchBooks = async () => {
      try {
        const booksData = await getBooksData();
        setBooks(booksData);
      } catch (error) {
        console.error("Error fetching books", error);
        setError("Unable to fetch books. Please try again.");
      }
    };
    fetchBooks();
  }, []);


  useEffect(()=> {
    const fetchTax = async ()=> {
      try{
        const pagesData = await getDataFromTaxonomy();
        // console.log("pages tax", pagesData);
        setTaxonomyData(pagesData);
      }
      catch(error){
        console.error("Error fetching books", error);
      }
    };
    fetchTax();
  },[])


 const isHTMLContent = (data: string) => /<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>/i.test(data);

 const selectedFormat = taxonomyData.find((data)=> data.id === format)?.value;
 console.log("selectedformat", selectedFormat);

 const selectedFontType = taxonomyData.find((data) => data.id === fontType)?.value;
 console.log("selectedFontType", selectedFontType);

const getFontFamaily = () => {
  if(selectedFontType === 'Quran' || selectedFontType === 'Urdu'){
    return "AIQalamQuranurdu";
  }
  else if(selectedFontType === 'Arabic'){
    return "Arabic";
  }
   else if(selectedFontType === 'English'){
    return "English";
  }
  return 'inherit';
}

  useEffect(() => {
    const handleGetApiToEdit = async () => {
      if (id) {
        try {
          const pagesToEdit = await getPageByIdApi(id);
          if (pagesToEdit) {
            setBookId(pagesToEdit.bookId);
            setPageNo(pagesToEdit.pageNo);
            setSequence(pagesToEdit.sequence);
            setContent(pagesToEdit.contentWithHtml);
            setPlainText(pagesToEdit.content);
            setType(pagesToEdit.type);
            setFormat(pagesToEdit.format);
            setFontType(pagesToEdit.fontType);
            setRenderType(pagesToEdit.renderType);
            setPagePublishStatus(pagesToEdit.status)
            setIsEditMode(true);
          }
        } catch (error) {
          console.error("Unable to fetch page data ", error);
        }
      }
    };
    handleGetApiToEdit();
  }, [id]);

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (isEditMode && (pageNo === "" || bookId === "" )) {
      setError("All fields are required.");
      return;
    }

    if (!isEditMode && (pageNo === ""  )) {
      setError("All fields are required.");
      return;
    }
    if(selectedFormat === 'Text' && !fontType && !renderType){
      setError("FontType and RenderType required when Text selected")
      return;
    }

    const sequenceNumber = Number(sequence);
    if (isNaN(sequenceNumber)) {
      setError("Sequence must be a valid number.");
      return;
    }

    setIsLoading(true);
    setError(null);
    try {
      if (isEditMode) {
        await EditPageApi(id, bookId, pageNo, sequenceNumber, value,plainText, type, format, fontType, renderType,pagePublishStatus );
        // alert("Page edited successfully");
          toast.success("Page edited successfully");

      } else {
        selectedBookName.map(async (book) => {
          const bookId = book.id;
          return await AddpageApi(pageNo, sequenceNumber, value,plainText, bookId, type, format, fontType, renderType);
          // return await AddpageApi(pageNo, sequenceNumber, value, bookId);
        });
        toast.success("Page added successfully");
        // alert("Page added successfully");
      }
      navigate("/admin/pages");
    } catch (error) {
      console.error("Error in submitting the form", error);
      setError("Error in submitting the form. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEditMode && content) {
      setValue(content);
    }
  }, [isEditMode, content]);

  const handleCancel = () => {
    navigate("/admin/pages");
  };

  const config: any = useMemo(
    () => ({
      readonly: false,
      placeholder: "",
    }),
    []
  );

  return (
    <div className="w-full pr-16">
      <div className="flex-grow p-6">
        <form>
          <h1 className="text-3xl font-bold text-blue-500 mb-5">
            {isEditMode ? "Edit Page" : "Add Page"}
          </h1>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {isEditMode && (
            <div className="mb-4">
              <label className="block text-gray-900 dark:text-gray-200 font-semibold">Book Name</label>
              <select
                required
                value={bookId}
                onChange={(e) => setBookId(e.target.value)}
                className="w-full px-3 py-2 border rounded-md"
              >
                <option value="" disabled>
                  Select a book
                </option>
                {books.map((book) => (
                  <option key={book.id} value={book.id}>
                    {book.name}
                  </option>
                ))}
              </select>
            </div>
          )}

   

          {!isEditMode && (
            <div className="mb-4 ">
              <label className="block text-gray-900 dark:text-gray-200 font-semibold">Book Name</label>
              <div className=" w-full bg-white border rounded-md mt-1 max-h-40 overflow-y-auto z-10">
                {selectedBookName.map((book) => (
                  <div key={book.id} className="px-3 py-2 hover:bg-gray-100 ">
                    {book.name}
                  </div>
                ))}
              </div>
            </div>
          )}

          <div className="mb-4 ">
            <label className="block text-gray-900 dark:text-gray-200 font-semibold">PageNo</label>
            <input
              required
              type="text"
              value={pageNo}
              onChange={(e) => setPageNo(e.target.value)}
              className="w-full px-3 py-2 border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
            />
          </div>
          
          <div className="mb-4 ">
            <label className="block text-gray-900 dark:text-gray-200 font-semibold">Type</label>
            <select className=" w-full px-3 py-2 text-gray-800  bg-white border rounded-md mt-1 max-h-40 overflow-y-auto z-10 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              
              value = {type}
              onChange={(e)=> setType(e.target.value)}
            >
              <option >Select Type</option>
             {taxonomyData.filter((data)=> data.type === "Page Content Type")
             .map((data)=> (
              <option key={data.id}  value={data.id}> {data.value}</option>
              
             ))
             }
            </select>
          </div>


          <div className="mb-4 ">
            <label className="block text-gray-900 dark:text-gray-200 font-semibold">Format</label>
            <select className=" w-full px-3 py-2 text-gray-800  bg-white border rounded-md mt-1 max-h-40 overflow-y-auto z-10 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              
              value={format}
              onChange = {(e)=> setFormat(e.target.value)}
            >
              <option>Select Format Type</option>
             {taxonomyData.filter((data)=> data.type === "Page Content Format")
             .map((data)=> (
              <option key={data.id}  value={data.id}>{data.value}</option>
             ))
             }
            </select>
          </div>

        
           <div className="mb-4 ">
            <label className="block text-gray-900 dark:text-gray-200 font-semibold">Font Type</label>
            <select className=" w-full px-3 py-2 text-gray-800  bg-white border rounded-md mt-1 max-h-40 overflow-y-auto z-10 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring" 
             
              value = {fontType}
              onChange={(e)=> setFontType(e.target.value)}
              disabled = {selectedFormat === 'Html'}
            >
                <option>Select Font Type</option>
              {taxonomyData.filter((data)=> data.type === "Page Font Type")
             .map((data)=> (
              <option key={data.id}  value={data.id}>{data.value}</option>
             ))
             }
            </select>
          </div>

          <div className="mb-4 ">
            <label className="block text-gray-900 dark:text-gray-200 font-semibold">Render Type</label>
            <select className=" w-full px-3 py-2 text-gray-800  bg-white border rounded-md mt-1 max-h-40 overflow-y-auto z-10 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
       
             value = {renderType}
             onChange={(e)=> setRenderType(e.target.value)}
             disabled = {selectedFormat === 'Html'}
            >
              <option>Select Render Type</option>
             {taxonomyData.filter((data)=> data.type === "Page Content Render Type")
             .map((data)=> (
             
              <option key={data.id}  value={data.id}>{data.value}</option>
             ))
             }
            </select>
          </div>
         


          {isEditMode ? (
          isHTMLContent(content)? (
            <div className="mb-4">
              {/* HTML Editor */}
              <div>
              <label className="block text-gray-900 dark:text-gray-200 font-semibold">Content (With HTML)</label>
              </div>
              <div className="w-full border-2 rounded-t-xl mt-2">
                <Editor
                  content={value}
                   setContent={(newContent: string) => {
                   setValue(newContent);
                  setContent(newContent);
                  }}
                  config={config}
                 />
              </div>

              {/* Plain Text */}

            </div>
          ): (
            <>

            {/* Only Plain Text */}

          </>
          )
          ): (
            selectedFormat === "Html" && (
              <div className="mb-4">
                <div className="flex gap-6 ">
                  <label className="block text-gray-900 dark:text-gray-200 font-semibold">Content (With HTML)</label>
                </div>
                <div className="w-full border-2 rounded-t-xl mt-2">
                  <Editor
                    content={value}
                    setContent={(newContent: string) => {
                      setValue(newContent);
                    }}
                    config={config}
                  />
                </div>
              </div>
          ))}

       

      {format && (
        <div>
          <div className="flex gap-6 ">
            <label className="block text-gray-900 dark:text-gray-200 font-semibold">Content</label>
          </div>
          <div className="w-full   mt-2  bg-white border rounded-md   overflow-y-auto z-10">
            <textarea
              className="w-full px-3 py-2 border-none outline-none"
              name="postContent"
              rows={4}
              cols={40}
              value={plainText}
              onChange={(e) => setPlainText(e.target.value)}
              style={{fontFamily: getFontFamaily()}}
            />
          </div>
        </div>
      )}

      {/* {selectedFormat === 'Text'? (
         <div>
         <div className="flex gap-6 text-gray-700">
           <label className="block text-gray-700">Content</label>
         </div>
         <div className="w-full   mt-2  bg-white border rounded-md   overflow-y-auto z-10">
           <textarea
             className="w-full px-3 py-2 border-none outline-none"
             name="postContent"
             rows={4}
             cols={40}
             value={plainText}
             onChange={(e) => setPlainText(e.target.value)}
             style={{fontFamily: getFontFamaily()}}
           />
         </div>
       </div>
      ) : (<></>)} */}



          <div className="flex space-x-4 mt-10">
            <button
              type="button"
              onClick={handleFormSubmit}
              className={`px-6 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 ${
                isLoading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={isLoading}
            >
              {isEditMode ? "Edit" : "Add"}
            </button>
        
            <button
              type="button"
              onClick={handleCancel}
              className="px-6 py-2 font-medium tracking-wide text-gray-600 capitalize transition-colors duration-300 transform bg-gray-300 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring focus:ring-gray-400 focus:ring-opacity-80"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    
    </div>
  );
}

export default FormPages;
