import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "@11solutionspvtltd/eleven-solutions-common-website";
import userProfileReducer from "../redux/slices/userProfileSlice";

const store = configureStore({
  reducer: {
    user: userSlice,
    userProfile: userProfileReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
