
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FetchLanguagesApi } from "../../api/Language";
import { createFestivalApi, editFestivalApi, fetchFestivalByIdApi } from "../../api/Festivals";

interface Language {
  id: string;
  name: string;
}

const MonthsData = [
  {
    language: "English",
    months: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
  },
  {
    language: "Telugu",
    months: [
      "Chaitra",
      "Vaisakha",
      "Jyestha",
      "Ashadha",
      "Shravana",
      "Bhadra",
      "Ashvin",
      "Kartika",
      "Margashirsh",
      "Paush",
      "Magh",
      "Phalgun",
    ],
  },
  {
    language: "Urdu",
    months: [
      "Muharram",
      "Safar",
      "Rabi'al-Awwal",
      "Rabi'al-Thani",
      "Jumada'al-Awwal",
      "Jumada'al-Than",
      "Rajab",
      "Shaban",
      "Ramadan",
      "Shawwal",
      "Dhul Qadah",
      "Dhul Hijjah",
    ],
  },
];

const FestivalForm = () => {
  const [festivalName, setFestivalName] = useState<string>("");
  const [type, setType] = useState<string | number>("");
  const [selectLanguageId, setSelectLanguageId] = useState<string>("");

  const [sequence, setSequence] = useState<number>(0);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [day, setDay] = useState<string | number>("");
  const [month, setMonth] = useState<string | number>("");
  const [days, setDays] = useState<number[]>([]);
  const [festivalStatus, setFestivalStatus] = useState<number>(0);
  const [languageSelect, setLanguageSelect] = useState<Language[]>([]);      // languages data
  const [Months, setMonths] = useState<string[]>([]);

  const location = useLocation();
  const { id } = location.state || {};
  const navigate = useNavigate();

  const getDaysInMonth = (monthIndex: number) => {
    const daysInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
    return Array.from({ length: daysInMonth[monthIndex] || 0 }, (_, i) => i + 1);
  };

  useEffect(() => {
    const fetchLanguages = async () => {
      const data = await FetchLanguagesApi();
      setLanguageSelect(data);
    };
    fetchLanguages();
  }, []);


console.log("selectedlanid", selectLanguageId);
const selectLanguageNmae = languageSelect.find((lang)=>lang.id === selectLanguageId)?.name || "Not Found";
console.log("selectedlanname", selectLanguageNmae);

  useEffect(() => {
    const selectedLanguageMonths =
      // MonthsData.find((lang) => lang.language === selectLanguage)?.months || [];
      MonthsData.find((lang) => lang.language === selectLanguageNmae)?.months || [];
    setMonths(selectedLanguageMonths);
  }, [selectLanguageNmae]);

  const handleMonthChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMonth = parseInt(event.target.value);
    setMonth(selectedMonth);
    setDays(selectedMonth ? getDaysInMonth(selectedMonth - 1) : []);
    setDay("");
  };

  const handleAddClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!selectLanguageId || !festivalName || !type || !day || !month) {
      alert("Please fill all the required fields");
      return;
    }

    try {
      if (isEditMode) {
        await editFestivalApi(id, selectLanguageId, festivalName, Number(type), Number(day), Number(month));
        alert("Festival updated successfully");
      } else {
        await createFestivalApi(selectLanguageId, festivalName, Number(type), Number(day), Number(month));
        alert("Festival added successfully");
      }
    } catch (error) {
      console.error(`Unable to ${isEditMode ? "update" : "add"} festival`, error);
    }
    navigate(-1);
  };

  useEffect(() => {
    const fetchFestivalData = async () => {
      if (!id) return;
      try {
        const festivalToEdit = await fetchFestivalByIdApi(id);
        if (festivalToEdit) {
          setFestivalName(festivalToEdit.name);
          setSelectLanguageId(festivalToEdit.languageId);
          setType(festivalToEdit.type);
          const apiMonth = festivalToEdit.month;
          setMonth(apiMonth);
          setDays(getDaysInMonth(apiMonth - 1));
          setDay(festivalToEdit.day);
          setFestivalStatus(festivalToEdit.status);
          setSequence(festivalToEdit.sequence);
          setIsEditMode(true);
        }
      } catch (error) {
        console.error("Unable to fetch festival data", error);
      }
    };
    fetchFestivalData();
  }, [id]);

  const handleCancelButton = () => {
    setDay("");
    setMonth("");
    setFestivalName("");
    setSelectLanguageId("");
    setType("");
    setSequence(0);
    setIsEditMode(false);
    navigate("/admin/Festivals");
  };



  return (
    <>
      <section className="max-w-4xl dark:bg-gray-800">
        <h2 className="text-3xl font-bold text-center text-blue-600 capitalize dark:text-white">
          {isEditMode ? "Edit Festival" : "Add Festival"}
        </h2>

        <form>
          <div className="grid grid-rows-4 gap-6 mt-4 sm:grid-rows-4">
            <div>
              <label className="text-gray-900 dark:text-gray-200 font-semibold" htmlFor="language">
                Language <span className="text-red-500">*</span>
              </label>
              <select
                required
                value={selectLanguageId}
                onChange={(event) => setSelectLanguageId(event.target.value)}
                className="w-full px-4 py-2 mt-2 text-gray-800 bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option>Select a Language</option>
                {/* {languageSelect.map((l) => (
                  <option key={l.id} value={l.id}>
                    {l.name}
                  </option>
                ))} */}
                  {languageSelect.map((l) => (
                  <option key={l.id} value={l.id}>
                    {l.id === selectLanguageId? `${l.name}` : `${l.name}` }
                  </option>
                 ))}
                
              </select>
            </div>
            <div>
              <label className="text-gray-900 dark:text-gray-200 font-semibold" htmlFor="festivalName">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                required
                id="festivalName"
                type="text"
                placeholder="Festival name"
                value={festivalName}
                onChange={(event) => setFestivalName(event.target.value)}
                className="w-full px-4 py-2 mt-2 text-gray-800 bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              />
            </div>

            <div>
              <label className="text-gray-900 dark:text-gray-200 font-semibold" htmlFor="type">
                Type <span className="text-red-500">*</span>
              </label>
              <select
                required
                value={type}
                onChange={(event) => setType(event.target.value)}
                className="w-full px-4 py-2 mt-2 text-gray-800 bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
              >
                <option value="">Select Type</option>
                <option value="1">Georgian</option>
                <option value="2">Islamic</option>
              </select>
            </div>

            <div className="flex gap-6">
              <div>
                <label className="text-gray-900 dark:text-gray-200 font-semibold" htmlFor="">
                  Select Month <span className="text-red-500">*</span>
                </label>
                <select
                  required
                  value={month}
                  onChange={handleMonthChange}
                  className="w-full px-4 py-2 mt-2 text-gray-800 bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                >
                  <option value="">Select Month</option>
                  {Months.map((month, index) => (
                    <option key={index} value={index + 1}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="text-gray-900 dark:text-gray-200 font-semibold" htmlFor="">
                  Select Day <span className="text-red-500">*</span>
                </label>
                <select
                  required
                  value={day}
                  onChange={(event) => setDay(event.target.value)}
                  className="w-full px-4 py-2 mt-2 text-gray-800 bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                >
                  <option>select Day</option>
                  {days.map((d) => (
                    <option key={d} value={d}>
                      {d}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {/* {isEditMode && (
              <div>
                <label className="text-gray-900 dark:text-gray-200 font-semibold" htmlFor="sequence">
                  Sequence <span className="text-red-500">*</span>
                </label>
                <input
                  required
                  id="sequence"
                  type="number"
                  placeholder="Sequence"
                  value={sequence}
                  onChange={(event) => setSequence(Number(event.target.value))}
                  className="w-full px-4 py-2 mt-2 text-gray-800 bg-white border border-gray-400 rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-800 focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 dark:focus:border-blue-300 focus:outline-none focus:ring"
                />
              </div>
            )} */}

            <div className="flex justify-start gap-4 mt-2">
              <button
                className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
                type="submit"
                onClick={handleAddClick}
              >
                {isEditMode ? "Update" : "Add"}
              </button>

              <button
                className="px-8 py-2.5 leading-5 text-white transition-colors duration-300 transform bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:bg-gray-600"
                type="reset"
                onClick={handleCancelButton}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </section>
    </>
  );
};

export default FestivalForm;



//  {languageSelect.map((l) => (
//                   <option key={l.id} value={l.id}>
//                     {l.id === selectLanguageId? `${l.name}` : `${l.name}` }
//                   </option>
//                 ))}
