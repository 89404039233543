import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import React, { useEffect, useState, useCallback} from 'react'
import { useNavigate, useLocation} from 'react-router-dom';
import { getAllAyaData } from '../../api/Aya';
import { FetchLanguagesApi } from "../../api/Language";
import {GetAllFestival, editFestivalApi,  deleteFestivalApi  } from '../../api/Festivals';
import toast from 'react-hot-toast';
import { FaChevronDown, FaChevronUp, FaPlus } from "react-icons/fa";


interface Ayas {
    id: number,
    ar: string,
    num: number,
    engtext: string,
    urdutext: string,
    tafseer: string,
    pagenum: number,
    surahid: number,
    paraid: number,
    surahName: string,
    paraName: string
}

interface Festivals {
    id: string,
    name: string,
    language: string,
    type: number,
    day: number,
    month: number,
    updatedOn: string,
}

interface Language {
    id: string;
    name: string;
  }

const Festivals = () => {
    const [aya, setAya] = useState<Ayas[]>([]);
    const [search, setSearch] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
     const [isFormVisible, setIsFormVisible] = useState(false);

     const [languageSelect,setLanguageSelect] = useState<Language[]>([]);

    const [festivalData, setFestivalData] = useState<Festivals[]>([]);


    const navigate = useNavigate();
    const ITEMS_PER_PAGE = 10;
     const location = useLocation();

     useEffect(() => {
        const fetchLanguages = async () => {
          const data = await FetchLanguagesApi();
          setLanguageSelect(data);
        };
        fetchLanguages();
      }, []);

     const fetchFestivalData = useCallback(async () => {
        try {
          const festData: any = await  GetAllFestival();
          setFestivalData(festData);
          console.log("FestivalData", festivalData)
        }
        catch (error) {
          console.error("Failed to fetch language details");
        }
      }, [])

  useEffect(() => {
    fetchFestivalData();
  }, [location, fetchFestivalData]);

   
    const filteredfestival = festivalData.filter((festival) => {
        // console.log('Current Festival:', festival);
        return (
          (festival.name && festival.name.toLowerCase().includes(search.toLowerCase())) ||
        //   (festival.language && festival.language.toString().toLowerCase().includes(search.toLowerCase())) ||
          (festival.type && festival.type.toString().toLowerCase().includes(search.toLowerCase())) ||
          (festival.day && festival.day.toString().toLowerCase().includes(search.toLowerCase())) ||
          (festival.month && festival.month.toString().toLowerCase().includes(search.toLowerCase()))
        );
      });
    //   console.log('Filtered Festivals:', filteredfestival);
   

    const currentfest =   filteredfestival.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        currentPage * ITEMS_PER_PAGE
    );

    const totalPages = Math.ceil(filteredfestival.length / ITEMS_PER_PAGE);


    const handleEditClick = (id: string) => {
        navigate('/admin/Festivals/FestivalForm', { state: { id } });
    };

  const handleDeleteClick = async (id: string) => {
      try {
        await deleteFestivalApi(id);
        alert("Language deleted successfully");
  
        fetchFestivalData();
      } catch (error) {
        console.error("Error deleting language:", error);
      }
    };
   

    return (

        <div className='container h-full px-1 mx-auto'  >
            <h2 className="text-3xl font-bold text-blue-600 mb-5 mt-4">Festival Details</h2>
            <div className="flex-grow ml-0 w-full mt-10">
                <form className="w-full ">
                    <label
                        htmlFor="default-search"
                        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                    >
                        Search
                    </label>
                    <div className="relative w-full">
                        <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                            <svg
                                className="w-4 h-4 text-gray-500 dark:text-gray-400"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                            </svg>
                        </div>
                        <input
                            type="search"
                            id="default-search"
                            className="inline-block min-w-full p-3 ps-10 outline-none text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Search Festival's"
                            required
                            onChange={(e)=> setSearch(e.target.value)}
                        />
                        <button
                            type="submit"
                            className="text-white absolute end-1.5 bottom-1.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Search
                        </button>
                    </div>
                </form>
            </div>


            <div className="flex-grow ml-0 mt-4 w-full">
                <div className="overflow-hidden border border-gray-200 dark:border-gray-700 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                        <thead className="bg-gray-50 dark:bg-gray-800">
                            <tr>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    Actions
                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    Festival Name
                                </th>
                                 <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    Language
                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    <span>Type</span>
                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    <span>Day</span>
                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    Month
                                </th>
                                <th
                                    scope="col"
                                    className="px-4 py-3.5 text-md font-normal text-left rtl:text-right text-gray-500 dark:text-gray-400"
                                >
                                    <span>Updated On</span>
                                </th>

                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                            {currentfest.map((fest: any) => (
                                <tr key={fest.id}>
                                    <td className="px-4 py-4 text-sm whitespace-nowrap">
                                        <div className="flex items-center gap-x-6">
                                            <button
                                                onClick={() => handleEditClick(fest.id)}
                                                className="text-gray-500 transition-colors duration-200 dark:hover:text-yellow-500 dark:text-gray-300 hover:text-yellow-500 focus:outline-none"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                    strokeWidth="1.5"
                                                    stroke="currentColor"
                                                    className="w-5 h-5"
                                                >
                                                    <path
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                                                    />
                                                </svg>
                                            </button>

                                            <button
                                  onClick={() => handleDeleteClick(fest.id)}
                                  className="text-gray-500 transition-colors duration-200 dark:hover:text-red-600 dark:text-gray-300 hover:text-red-500 focus:outline-none"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="currentColor"
                                    className="w-5 h-5"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                                    />
                                  </svg>
                                </button>
                                        </div>
                                    </td>
                                    {/* <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        <div className="flex items-center gap-x-2">
                                            <p className="px-3 py-1 text-sm text-indigo-500 rounded-full dark:bg-gray-800 bg-indigo-100/70">
                                               
                                            </p>
                                        </div>
                                    </td> */}
                                    <td className="px-4 py-4 font-medium text-gray-800 dark:text-gray-300 whitespace-nowrap">
                                        {fest.name}
                                    </td>
                                  
                                    <td className="px-4 py-4 font-medium text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        {
                                            languageSelect.find((language)=> language.id === fest.languageId)?.name
                                        }
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        {fest.type}
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        {fest.day}
                                    </td>

                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        {fest.month}
                                    </td>
                                    <td className="px-4 py-4 text-sm text-gray-500 dark:text-gray-300 whitespace-nowrap">
                                        {fest.updatedOn.split("T")[0]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

           <div>
           <button
                onClick={() => {
                  navigate("/admin/Festivals/FestivalForm");
                }}
                className="mt-4 flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
              >
                <FaPlus className="mr-2 font-medium" />
                Festivals
              </button>
           </div>


             <div className="flex justify-center mt-4">
                 <button
                     className="px-4 py-2 bg-gray-300 text-gray-700 rounded-l-md hover:bg-gray-400"
                     disabled={currentPage === 1}
                     onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                >
                     <IconArrowLeft />
             </button>
             {[...Array(totalPages)].map((_, index) => (
                 <button
                    key={index}
                         className={`px-4 py-2 ${currentPage === index + 1
                            ? "bg-blue-600 text-white"
                            : "bg-gray-300 text-gray-700 hover:bg-gray-400"}`}
                    onClick={() => setCurrentPage(index + 1)}
                     >
                       {index + 1}
                     </button>
                 ))}
                
                 <button
                     className="px-4 py-2 bg-gray-300 text-gray-700 rounded-r-md hover:bg-gray-400"
                     disabled={currentPage === totalPages}
                 onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                >
                   <IconArrowRight />
               </button>
                  
            </div> 
        </div>
    );
};

export default Festivals
